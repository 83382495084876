<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">


<base-material-card
      icon="mdi-check"
      title="Vendas"
      class="px-5 py-3"
    >

<v-dialog
        v-model="dialog"
        width="500"
      >

        <v-card>
            <v-toolbar  text
                color="success"
                   dark > 
                <h3> Mensagem</h3>
             </v-toolbar>
          <v-card-text>
            {{ msg_dialog }}
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-simple-table>
        <thead>
          <tr>
          <th style="width: 25%;font-size:14px;font-weight: bold;">
              Nome
            </th>
          <th style="width: 8%;font-size:14px;font-weight: bold;">
              Data
            </th>
 
          <th style="width: 15%;font-size:14px;font-weight: bold;">
              Plano
            </th>
          <th style="width: 20%;font-size:14px;font-weight: bold;">
              Situação do pagamento
            </th>
            <th style="width: 20%;font-size:14px;font-weight: bold;">
              Opções
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in contratos"
            :key="item.id"
          >
            <td style="font-size:14px;">{{ item.nome }}</td>
            <td style="font-size:13px;">{{ item.dataContrato }}</td>
            <td style="font-size:13px;">{{ item.nome_plano }}</td>
            <td style="font-size:13px;">{{ item.situacao }}</td>
            <td>

          
             <v-btn :loading="loading3" color="warning"  small class="ma-2 white--text" fab @click="download_pdf(item.id)">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>


            <v-btn :loading="loading3" color="danger"  small class="ma-2 white--text" fab @click="download_zip(item.id)">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>


            <v-btn :loading="loading2" color="success" small class="ma-2 white--text" fab @click="aprovar(item, index)">
              <v-icon dark>mdi-check</v-icon>
            </v-btn>


            <v-btn :loading="loading2" color="red" small class="ma-2 white--text" fab @click="cancela(item, index)">
              <v-icon dark>mdi-close</v-icon>
            </v-btn>

            </td>
          </tr>
        </tbody>
      </v-simple-table>
  </base-material-card>

  </v-container>
</template>
<script>
  import Contrato from '../../../services/contrato'
  import axios from 'axios'
  import authHeader from '../../../services/auth-header';
  import config  from '../../../services/config'


  export default {


     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    data: () => ({
      contratos: [],
      msg_dialog: '',
      status_msg: 'success',
      dialog: false,
      loading2: false,
      loading3: false
    }),

    mounted () {
      this.listar()
    },

    methods: {
      listar () {
        Contrato.lista('Previda', 'ANÁLISE').then(response => {
            this.contratos = response.data
        }).catch(e => {
            if(e.response.status === 401){
            this.$store.dispatch('auth/logout');
            this.$router.push('/restrito/login');
            }

        }).finally(() => {

        })
      },

      aprovar(item, index){
            
            this.loading2 = true,

            Contrato.update(item.id).then(response => {
            this.contratos.splice(index, 1)
            this.dialog = true
            this.status_msg = 'success'
            this.msg_dialog = 'Contrato Aprovado com Sucesso'

        }).catch(e => {
            this.loading2 = false

        }).finally(() => {
            this.loading2 = false

        })
      },

      cancela(item, index){
            
            this.loading2 = true,
            Contrato.cancela(item.id).then(response => {
            this.contratos.splice(index, 1)
            this.dialog = true
            this.status_msg = 'success'
            this.msg_dialog = 'Contrato Cancelado com Sucesso'

        }).catch(e => {
            this.loading2 = false

        }).finally(() => {
            this.loading2 = false

        })
      },

      download_pdf (id) {
        this.loading3 = true

        axios({
          url: config.url + 'download_ficha?id=' + id,
          method: 'GET',
          headers: authHeader(),
          responseType: 'blob',
        }).then((response) => {
          
          this.loading3 = false

          if (response.status === 200) {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', 'ficha_' + id + '.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
          } else {
            this.dialog = true
            this.status_msg = 'danger'
            this.msg_dialog = 'Cliente não tem documentos'
          }
        }).catch(() => {
          this.dialog = true
          this.status_msg = 'danger'
          this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
         }).finally(() => {
          this.loading3 = false

        })
      },
      download_zip (id) {
          this.loading3 = true

        axios({          
          url: config.url + 'download_zip?id=' + id,
          method: 'GET',
          headers: authHeader(),
          responseType: 'blob',
        }).then((response) => {
          this.loading3 = false

          if (response.status === 200) {
              if (response.data.size > 1) {
                  const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                  const fileLink = document.createElement('a')
                  fileLink.href = fileURL
                  fileLink.setAttribute('download', 'docs_' + id + '.zip')
                  document.body.appendChild(fileLink)
                  fileLink.click()
              }else{
                  this.dialog = true
                  this.status_msg = 'danger'
                  this.msg_dialog = 'Não existe documento anexo ao contrato'
          
              }

           
          } else {
            this.dialog = true
            this.status_msg = 'danger'
            this.loading3 = false
            this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
          }
        }).catch(() => {
          this.dialog = true
          this.loading3 = false
          this.status_msg = 'danger'
          this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
       }).finally(() => {
          this.loading3 = false

        })
      },
    },
  }

</script>
